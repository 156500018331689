<template>
  <div class="new-team-form">
    <template v-if="loadingState.invitation">
      <div class="loader-text">{{ $t('global.loading') }}</div>
      <loader class="registration-loader" />
    </template>

    <team-invitation-form
      v-else-if="team"
      :hash="newTeamHash"
      :invitation-expiration="invitationExpiration"
      :invitation-hash="invitationHash"
      @renew="renewHash"
    />

    <BaseForm v-else @submit="save">
      <BaseInput
        v-model="name"
        :required="true"
        :label="$t('teams.edit_labels_teamName')"
        :placeholder="$t('teams.edit_placeholder_title')"
        :error-message.sync="errors.name"
      />

      <GameSelect
        v-if="!defaultGameId"
        v-model="gameId"
        :required="true"
        :label="$t('teams.info_game')"
        :error-message.sync="errors.gameId"
      />

      <div v-if="!defaultSize" class="team-size-group">
        <BaseSelect
          v-model="size"
          :required="true"
          :readonly="true"
          :label="$t('teams.create_teamSize')"
          :options="teamSizes"
          :message="$t('teams.create_teamSizeMain')"
          :placeholder="$t('teams.create_enterCount')"
        />

        <BaseInput
          v-model="spareCount"
          :disabled="true"
          :label="$t('teams.create_spares')"
          :message="$t('teams.create_spareSlots')"
        />
      </div>

      <CountrySelect
        v-model="countryId"
        :label="$t('teams.info_country')"
      />

      <div class="logo-upload">
        <div class="label">{{ $t('teams.logo_title') }}</div>
        <div v-if="files.length && !uploadError" class="cropper">
          <pvp-btn
            type="button"
            icon-left="cross"
            variant="clear"
            @click="clearFiles"
          />
          <image-cropper
            :height="250"
            :width="250"
            :src="files[0].src"
            @result="afterCropResult"
          />
        </div>
        <template v-else>
          <files-uploader
            v-model="files"
            :allowed-formats="organizationIconFormats"
            :max-file-size="organizationIconMaxMbSize"
            @input="setFilesUploaderError"
          />
          <div class="message">{{ $t('teams.logo_hint') }}</div>
        </template>
      </div>
      <!-- eslint-disable vue/no-v-html -->
      <InfoBox v-if="hasErrors" variant="error">
        <div v-html="errorText"></div>
      </InfoBox>

      <template v-if="needToLinkGameAccount">
        <InfoBox variant="warning">
          {{ $t('teams.gameError', { game: game.name }) }}
        </InfoBox>

        <div class="form-controls">
          <game-account-linker :id="game.id" />
        </div>
      </template>

      <div v-else class="form-controls">
        <pvp-btn
          v-if="hasBackButton"
          variant="secondary"
          :is-loading="loadingState.update"
          @click="$emit('back')"
        >
          {{ $t('global.back') }}
        </pvp-btn>
        <pvp-btn
          type="submit"
          :disabled="uploadError || loadingState.update"
          :is-loading="loadingState.update"
        >
          {{ $t('teams.create_button') }}
        </pvp-btn>
      </div>
    </BaseForm>
  </div>
</template>

<script>
import GameAccountLinker from '@components/ProfileComponents/GameAccount/GameAccountLinker.vue';
import FilesUploader from '@components/BaseComponents/FilesUploader.vue';
import ImageCropper from '@components/BaseComponents/ImageCropper.vue';
import TeamInvitationForm from './TeamInvitationForm.vue';
import BaseForm from '@components/BaseComponents/Form/BaseForm.vue';
import BaseSelect from '@components/BaseComponents/Form/Select.vue';
import CountrySelect from '@components/BaseComponents/Select/CountrySelect.vue';
import BaseInput from '@components/BaseComponents/Form/BaseInput.vue';
import GameSelect from '@components/BaseComponents/Select/GameSelect.vue';
import InfoBox from '@components/BaseComponents/InfoBox.vue';

export default {
  name: 'NewTeamForm',
  components: {
    ImageCropper,
    FilesUploader,
    GameAccountLinker,
    TeamInvitationForm,
    BaseForm,
    BaseSelect,
    CountrySelect,
    BaseInput,
    GameSelect,
    InfoBox,
  },
  props: {
    hash: {
      type: String,
      default: null,
    },
    defaultGameId: {
      type: Number,
      default: null,
    },
    defaultSize: {
      type: Number,
      default: null,
    },
    hasBackButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // State
      loadingState: {
        ...this.loadingState,
        update: false,
        invitation: false,
      },
      newTeamModalOpened: false,

      // Data
      organizationIconMaxMbSize: 5,
      organizationIconFormats: ['jpg', 'jpeg', 'png'],
      name: null,
      gameId: null,
      size: 2,
      countryId: null,

      teamSizeLimit: 10,
      spareCounts: [],

      invitationHash: null,
      invitationExpiration: null,
      newTeamHash: null,
      files: [],
      cropResult: {},

      // Errors
      filesUploaderError: false,
      errors: {
        name: '',
        gameId: '',
      },
      success: false,
    };
  },
  computed: {
    ...mapGetters('teams', ['getTeam']),
    ...mapGetters('application', ['getGame']),
    ...mapGetters('profile', [
      'getLinkedGameAccount',
      'getCurrentProfile',
      'getCurrentProfileHash',
    ]),

    uploadError() {
      return Boolean(this.files[0]?.error);
    },

    hasErrors() {
      return (
        Object.values(this.errors).filter((value) => value).length > 0
      );
    },

    errorText() {
      return (
        this.errors.global ||
        this.errors.idCaptain ||
        this.$t('save.error')
      );
    },

    team() {
      return this.getTeam(this.newTeamHash);
    },

    game() {
      return this.getGame(this.gameId);
    },

    hasGameAccount() {
      return this.getLinkedGameAccount(this.gameId)?.hasAccount;
    },

    needToLinkGameAccount() {
      return (
        this.game && this.game.code !== 'oth' && !this.hasGameAccount
      );
    },

    teamSizes() {
      return _.range(1, this.teamSizeLimit + 1);
    },

    spareCount() {
      return _.reduce(
        this.spareCounts,
        (acc, spare, size) => (this.size >= size ? spare : acc),
        0,
      );
    },
  },
  created() {
    api.get('/team/settings').then((res) => {
      this.teamSizeLimit = res.teamSizeLimit;
      this.spareCounts = res.numberReservePlayers;
    });

    if (this.hash) {
      this.loadingState.invitation = true;

      this.getInvitationLink(this.hash).then(
        ({ inviteHash, expiredTime = null }) => {
          if (inviteHash) {
            this.invitationExpiration = expiredTime;
            this.invitationHash = inviteHash;

            this.loadingState.invitation = false;
          } else {
            this.renewHash();
          }

          this.newTeamHash = this.hash;
        },
      );
    }

    const profile = this.getCurrentProfile;

    this.countryId = profile.idCountry;

    this.gameId = this.defaultGameId;

    if (this.defaultSize) {
      this.size = this.defaultSize;
    }
  },
  methods: {
    ...mapActions('teams', [
      'createTeam',
      'getInvitationLink',
      'renewInvitationLink',
    ]),
    ...mapActions('users', ['fetchUserProfile']),

    setFilesUploaderError(data) {
      this.filesUploaderError = Boolean(data[0].error);
    },

    renewHash() {
      this.renewInvitationLink(this.hash).then(
        ({ inviteHash, expiredTime = null }) => {
          this.invitationExpiration = expiredTime;
          this.invitationHash = inviteHash;

          this.loadingState.invitation = false;
        },
      );
    },

    showNewTeamModal() {
      this.newTeamModalOpened = true;
    },

    save() {
      this.success = false;
      this.errors = {};
      this.loadingState.update = true;

      this.createTeam({
        name: this.name,
        gameId: this.gameId,
        size: this.size,
        countryId: this.countryId,
      })
        .then((result) => {
          this.success = true;
          this.$emit('send', 'NEXT');

          this.invitationExpiration =
            +(Date.now() / 1000).toFixed(0) + 24 * 60 * 60;
          this.invitationHash = result.invitationHash;
          this.newTeamHash = result.team.hash;

          if (this.files.length) {
            this.submitAvatar(this.newTeamHash).then(() =>
              this.updateUserProfile(result),
            );
          } else {
            this.updateUserProfile(result);
          }
        })
        .catch(({ error = [] }) => {
          this.errors = _.reduce(
            error,
            (acc, err, field) => ({
              ...acc,
              [field === 'idGame' ? 'gameId' : field]: err.replace(
                /[&]quot[;]/giu,
                '"',
              ),
            }),
            {},
          );
        })
        .finally(() => {
          this.loadingState.update = false;
        });
    },

    updateUserProfile(result) {
      this.fetchUserProfile({
        hash: this.getCurrentProfileHash,
        complex: true,
      }).then(() => {
        this.$emit('success', result);
      });
    },

    afterCropResult(data) {
      this.cropResult = data;
    },

    submitAvatar(teamhash = '') {
      this.error = '';
      this.isLoading = true;

      const formData = new FormData();
      formData.append('file', this.files?.[0]?.file);
      formData.append('type', 'team');
      formData.append('hash', teamhash);
      Object.entries(this.cropResult).forEach(([key, value]) =>
        formData.append(key, value),
      );

      return api
        .post('/file/uploadandresize', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
        .then((data) => {
          this.$emit('updated', data);
          this.isOpen = false;
        })
        .catch(() => {
          this.error = this.$t('save.error');
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    clearFiles() {
      this.files = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.new-team-form {
  .team-size-group {
    display: flex;
    justify-content: space-between;

    .select,
    .label {
      margin-top: 0;
      flex-grow: 1;
      flex-basis: 0;
      max-width: 48%;
    }

    &,
    & + .select {
      margin-top: em(12px);
    }
  }

  .form-controls {
    margin-top: 30px;
    text-align: center;

    .button + .button {
      margin-left: 1em;
    }
  }

  .loader {
    margin-top: 20px;

    &-text {
      text-align: center;
      font-size: 18px;
      font-weight: 600;
    }
  }

  .logo-upload {
    margin-top: em(12px);
    color: rgba(white, 0.5);
    font-size: em(14px);

    .message {
      font-size: em(13px);
      color: rgba(white, 0.4);
    }
  }
}

.cropper {
  position: relative;
  @include max-tablet() {
    margin-left: -12px;
    margin-right: -12px;
  }

  .image-cropper {
    background-color: transparent;
    border: 1px dashed rgba(white, 0.1);
  }

  .button-clear {
    position: absolute;
    right: 0;
    color: rgba(white, 0.4);
    font-size: 16px;
    padding: 12px;
  }
}

.pvp-info-box {
  margin-top: 10px;
}
</style>
